import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { LicensePlateAnalyze, LicensePlateDemoLogin, demoLPRAnalyze } from "../../../data/car_parking/LicensePlate/licensePlateServices";
import { Box, Button, Container, CssBaseline, Grid, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { uploadImage } from "../../../data/dataServices";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import moment from "moment/moment";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        padding: "8px",
        '& .MuiButton-root' : {
            textDecoration: "none"
        },
    },
    formSidePanel: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        height: "100%",
    },
    formBody: {
        display: "contents",
        '& div .displayImage': {
        },
        '& div .deleteIcon': {
            position: "absolute",
            marginLeft: "-25px",
            color: "red",
            cursor: "pointer"
        },
        '& div .deleteIcon:hover': {
            backgroundColor: "lightgray",
        }
    },
    
}));

const LicensePlateDemoModule = () => {
    const classes = useStyles();

    const [error, setError] = useState(null);
    const [username, setUsername] = useState("bv001");
    const [password, setPassword] = useState("abc123");
    const [accessToken, setAccessToken] = useState("");
    const [files, setFiles] = useState([]);
    const [analyzeDatas, setAnalyzeDatas] = useState([]);
    const [analyzing, setAnalyzing] = useState(false);
    const [analyzingItem, setAnalyzingItem] = useState(-1);

    const handleDangNhap = () => {
        LicensePlateDemoLogin(username, password).then((res) => {
            setAccessToken(res.data.accessToken)
        }).catch((err) => {
            console.log(err);
            setError(err);
        }).finally(() => {
        })
    }

    const loginForm = () => (
        <div id="form-body" className={classes.formBody}>
            <table style={{width: "100%"}}>
                <tr>
                    <td style={{width: "100%"}}>
                        <div style={{textAlign: "right", fontSize: "30px", color:"green", fontWeight: "bold"}}>GREEN MAKE COMPANY LIMITED</div>
                        <div style={{textAlign: "right", fontSize: "30px", color:"green"}}>PROFESSIONAL SOFTWARE CONSULTANT AND DEVELOPING</div>
                    </td>
                    <td><img src="/logo192.png" alt="Upload" height="100px" /></td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <Typography fullWidth textAlign="center" component="h6" variant="h6" style={{color:"red"}}>
                            MÔ PHỎNG HỆ THỐNG NHẬN DẠNG BIỂN SỐ XE
                        </Typography>
                        <Typography fullWidth textAlign="center" component="h6" variant="h6" style={{color:"red"}}>
                            ỨNG DỤNG CÔNG NGHỆ TRÍ TUỆ NHÂN TẠO AI
                        </Typography>
                    </td>
                </tr>
            </table>
            <Container component="main" maxWidth="xs" className={classes.root}>
                <CssBaseline />
                <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box component="form" noValidate sx={{ mt: 1 }}>
                        <TextField
                            value={username}
                            onChange={event => setUsername(event.target.value)}
                            fullWidth
                            margin="normal"
                            id="username"
                            label="Tên đăng nhập"
                            name="username"
                            autoComplete="username"
                            required
                            autoFocus
                        />
                        <TextField
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                            fullWidth
                            margin="normal"
                            id="password"
                            label="Mật khẩu"
                            name="password"
                            autoComplete="current-password"
                            type="password"
                            required
                        />

                        <Button variant="contained" onClick={handleDangNhap} >
                            Đăng nhập
                        </Button>
                    </Box>
                </Box>
            </Container>
            <div style={{padding:"20px"}}>
                <Typography fullWidth style={{color:"green", fontSize: "25px"}}>
                    Ghi chú:
                </Typography>
                <Typography fullWidth style={{color:"green", padding:"10px 20px 10px 20px"}}>
                    - Click chọn “Đăng nhập” để vào màn hình mô phỏng nhận dạng biển số. Vui lòng đừng thay đổi thông tin – bạn sẽ không đăng nhập được.
                </Typography>
                <Typography fullWidth style={{color:"green", padding:"10px 20px 10px 20px"}}>
                    - Sau khi thực hiện mô phỏng, bạn có góp ý hoặc yêu cầu tích hợp sử dụng sản phẩm AI này cho ứng dụng của bạn, hãy liên hệ với Công ty TNHH Tạo Xanh để được tư vấn giải pháp phù hợp.
                </Typography>
                <Typography fullWidth style={{color:"green", padding:"10px 20px 10px 20px"}}>
                    - Sản phẩm này có thể ứng dụng cho đa nền tảng Windows, Linux, Android, IOS hoặc lập trình vi xử lý bo mạch (ESP8266, raspberry). Mỗi một mô hình ứng có có mô hình giải pháp khác nhau, đáp ứng mọi nhu cầu dù nhỏ nhất của bạn.
                </Typography>
                <Typography fullWidth style={{color:"green", padding:"10px 20px 10px 20px"}}>
                    Người liện hệ: Mr Hiền, HP: 0939 66 05 06
                </Typography>
                <Typography fullWidth style={{color:"green", padding:"10px 20px 10px 20px"}}>
                    Xin cảm ơn và mong nhận được sự quan tâm, hợp tác.
                </Typography>
                <Typography fullWidth style={{color:"green", padding:"10px 20px 10px 20px"}}>
                    Trân trọng kính chào.
                </Typography>
            </div>
        </div>
    )
    useEffect(() => {
        if(analyzingItem >= files.length){
            setAnalyzing(false);
        }
        if(0 > analyzingItem || analyzingItem >= files.length) return;

        var item = {};
        item.startTime = moment().format("HH:mm:ss.SSS");
        /*uploadImage(files[analyzingItem].file, accessToken).then((res) => {
            item.uploadedPath = res.data.path
            LicensePlateAnalyze(res.data.path, accessToken).then((res) => {
                if(res.data.data.length > 0){
                    item.licenseImage = "https://cvp.gmcom.asia" + res.data.data[0].licenseImage;
                    item.licensePlate = res.data.data[0].countrys[0].text;
                    item.processTime = res.data.time.toFixed(2);
                    item.endTime = moment().format("HH:mm:ss.SSS");
                }else{
                    item.licenseImage = null;
                    item.licensePlate = "Không tim thấy";
                    item.processTime = "";
                    item.endTime = moment().format("HH:mm:ss.SSS");
                }
                setAnalyzeDatas([...analyzeDatas, item]);
                setAnalyzingItem(analyzingItem + 1);
            }).catch((err) => {
                item.licenseImage = null;
                item.licensePlate = "Không tim thấy";
                item.processTime = "";
                item.endTime = moment().format("HH:mm:ss.SSS");
                setAnalyzeDatas([...analyzeDatas, item]);
                console.log(err);
                setError(err);
                setAnalyzingItem(analyzingItem + 1);
            }).finally(() => {
            })
        }).catch((err) => {
            console.log(err);
            setError(err);
            //setAnalyzing(false);
            setAnalyzingItem(analyzingItem + 1);
        }).finally(() => {
        })*/
        demoLPRAnalyze(files[analyzingItem].file, accessToken).then((res) => {
            if(res.data.data.length > 0){
                item.licenseImage = res.data.data[0].licenseImage;
                item.licensePlate = res.data.data[0].countrys[0].text;
                item.processTime = res.data.time.toFixed(2);
                item.endTime = moment().format("HH:mm:ss.SSS");
            }else{
                item.licenseImage = null;
                item.licensePlate = "Không tim thấy";
                item.processTime = "";
                item.endTime = moment().format("HH:mm:ss.SSS");
            }
            setAnalyzeDatas([...analyzeDatas, item]);
            setAnalyzingItem(analyzingItem + 1);
        }).catch((err) => {
            console.log(err);
            item.licenseImage = null;
            item.licensePlate = "Không tim thấy";
            item.processTime = "";
            item.endTime = moment().format("HH:mm:ss.SSS");
            setAnalyzeDatas([...analyzeDatas, item]);
            setAnalyzingItem(analyzingItem + 1);
        }).finally(() => {
        })
    }, [analyzingItem])

    const handleFileUpload = () => event => {
        let file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setFiles([...files, {value: reader.result, file: file}]);
        };

        reader.readAsDataURL(file);
    }

    const handleAnalyze = () => {
        setAnalyzing(true);
        setAnalyzingItem(0);
        setAnalyzeDatas([]);
    }

    const handleDeleteImage = (file) =>{
        const pos = files.indexOf(file)
        const newItems = files.filter((item, ind) => ind !== pos);
        setFiles(newItems);
    }

    const formBody = () => (
        <div id="form-body" className={classes.formBody}>
            <div className={classes.formControl} style={{paddingBottom: "20px"}}>
                <label htmlFor="upload-image">
                    <Button fullWidth variant="contained" component="span">
                        Upload image (Upload hình các biển số xe cần nhận dạng(Tối đa 10 hình))
                    </Button>
                    <input
                        id="upload-image"
                        disabled={files.length>=10}
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={handleFileUpload()}
                    />
                </label>
            </div>
            <Grid container spacing={2}>
                {files.map(file => {
                    return <Grid style={{clear:"both"}} item xs={2.4}>
                        <div><img className="displayImage" src={file['value']} alt="Upload" style={{maxWidth: "100%"}}/>
                        <DeleteForeverIcon className="deleteIcon" onClick={e=>handleDeleteImage(file)}></DeleteForeverIcon></div>
                    </Grid>
                })}
            </Grid>
            <LoadingButton fullWidth loading={analyzing} variant="contained" onClick={handleAnalyze} style={{height: "50px"}}>
                Bắt đầu thực hiện
            </LoadingButton>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>
                        Kết quả nhận dạng bằng AI:
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>
                        Hình BS
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>
                        KQ nhận dạng
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>
                        Thời gian nhận dạng ms
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>
                        Thời gian chạy
                    </Typography>
                </Grid>
                {analyzeDatas.map(item => {
                    return (<>
                            <Grid item xs={3}>
                                {item['licenseImage'] != null && item['licenseImage'] != "" && <img src={item['licenseImage']} alt="Upload" style={{maxWidth: "100%"}}/>}
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    {item['licensePlate']}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    {item['processTime']}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    {item['startTime']} - {item['endTime']} 
                                </Typography>
                            </Grid>
                        </>)
                        
                })}
            </Grid>
        </div>
    )


    return (
        <>
            {(accessToken == null || accessToken == "") && loginForm()}
            {accessToken != null && accessToken != "" && formBody()}
        </>
    );
}


export default LicensePlateDemoModule;